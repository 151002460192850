import React from 'react';
import { toast } from 'react-toastify';
import { deleteNodeEdge } from '../../actions/deleteNodeAction';
import ToastMessage from './toast-message';

export default function DeleteNode({ setLoading, selectedNode = [] }) {
  const handleDeleteNode = async () => {
    setLoading(true);

    // const isSpouse = selectedPath.length == 0 ? true : false;

    try {
      const res = await deleteNodeEdge(selectedNode.uid);
      // getFamilyDetails(selectedNode,selectedPath,isSpouse);
      console.log(res);
      setLoading(false);
      if (res?.status) {
        toast.success(`Node - ${selectedNode.name} deleted successfully`);
        toast.info(<ToastMessage message={'Click Okay to reload page to reflect changes'} refreshRequired={true} />, {
          autoClose: false,
        });
      } else toast.error(`Node - ${selectedNode.name} could not be deleted\n Error: ${res.error_message}`);
    } catch (error) {
      console.log(`Node - ${selectedNode.name} could not be deleted`, error);
      toast.error(`Node - ${selectedNode.name} could not be deleted\n Error: ${error}`);
      setLoading(false);
    }
  };

  return (
    <button
      onClick={() => {
        if (window.confirm(`Are you sure you want to delete ${selectedNode.name}'s node`)) {
          handleDeleteNode();
        }
      }}
    >
      Delete Node
    </button>
  );
}
