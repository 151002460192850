import React from 'react'
import { lookup_child } from '../../helpers/merge_family_test';
import DeleteMarriage from './delete-marriage-node';
import DeleteNode from './delete-node';
import DeleteParent from './delete-parental-node';

export default function DeleteNodes({selectedNode = [],treeData = [], setLoading}) {

    const areChildrenAdded = (selectedNode, treeData) => {
        if (selectedNode.gender == 'female' && selectedNode.spouse) {
          const spouse_id = selectedNode.spouse.uid;
    
          // Search for spouse to check for children
          for (let i = 0; i < treeData.length; ++i) {
            const selectedNode_spouse = lookup_child(treeData[i], spouse_id);
            if (selectedNode_spouse) {
              if (selectedNode_spouse.children.length > 0) return true;
              return false;
            }
          }
        }
    
        return selectedNode.children && selectedNode.children.length > 0 ? true : false;
      };
    
      const isFatherAdded = (selectedNode, treeData) => {
        for (let i = 0; i < treeData.length; ++i) {
          if (treeData[i].uid == selectedNode.uid) return false;
        }
        return true;
      };

  return (
    <div>
    {(selectedNode.spouse || areChildrenAdded(selectedNode,treeData)) ? <DeleteMarriage selectedNode = {selectedNode} setLoading = {setLoading} /> : ''}
    {(isFatherAdded(selectedNode,treeData)) ? <DeleteParent selectedNode = {selectedNode} setLoading = {setLoading}/> : ''}
    {(!selectedNode.spouse && !areChildrenAdded(selectedNode,treeData) || !(isFatherAdded(selectedNode,treeData))) ? <DeleteNode selectedNode = {selectedNode} setLoading = {setLoading}/> : ''}
    </div>
  )
}
