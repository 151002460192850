var merge = require('deepmerge2');
const _ = require('lodash');
const { copy } = require('copy-anything');

function test_merge_main() {
  let existing_json_of_312 = {
    id: 312,
    name: '312',
    spouse: {
      name: '312s',
    },
    children: [
      {
        id: 3121,
        name: '',
        spouse: {
          name: '3121s',
        },
        children: [
          {
            id: 31211,
            name: '31211',
          },
        ],
      },
      {
        id: 3122,
        name: '3122',
      },
    ],
  };
  let incoming_of_312 = {
    id: 312,
    name: '312',
    spouse: {
      name: '312s',
    },
    children: [
      {
        id: 3121,
        name: '3121',
      },
      {
        id: 3122,
        name: '',
        spouse: {
          name: '3122s',
        },
        children: [
          {
            id: 31221,
            name: '31221',
          },
        ],
      },
    ],
  };
  let merged_json_1 = merge(incoming_of_312, existing_json_of_312);
  console.log('merged json 1 is', JSON.stringify(merged_json_1, null, 4));
  let merged_json_2 = merge(existing_json_of_312, incoming_of_312);
  console.log('merged json 2 is', JSON.stringify(merged_json_2, null, 4));
}

// TODO: json path should give this output
function lookup_parent(obj, id) {
  let children = obj['children'] || [];
  let matched_kid = _.find(children, { id: id });
  if (!matched_kid) {
    for (let i = 0; i < children.length; i++) {
      let found = lookup_parent(children[i], id);
      if (found) {
        return found;
      }
    }
    return false;
  } else {
    return obj;
  }
}

// TODO: json path should give this output
export const lookup_child = (obj, value_of_field_to_be_searched, field_to_be_searched = 'uid' ) => {
  if (obj[field_to_be_searched] == value_of_field_to_be_searched) return obj;

  let children = obj['children'] || [];

  for (let i = 0; i < children.length; i++) {
    let child = children[i];
    let found = lookup_child(child, value_of_field_to_be_searched);
    if (found) {
      return found;
    }
  }
  // If iterating didn't return any matching keys, return notFound.
  return false;
};

function get_family_json(node_id) {
  let family_data = {
    3122: {
      id: '312',
      name: '312',
      spouse: {
        name: '312s',
      },
      children: [
        {
          id: '3121',
          name: '3121',
        },
        {
          id: '3122',
          name: '3122',
          spouse: {
            name: '3122s',
          },
          children: [
            {
              id: '31221',
              name: '31221',
            },
          ],
        },
      ],
    },
    312: {
      id: '31',
      name: '31',
      spouse: {
        name: '31s',
      },
      children: [
        {
          id: '311',
          name: '311',
        },
        {
          id: '312',
          name: '312',
          spouse: {
            name: '312s',
          },
          children: [
            {
              id: '3121',
              name: '',
            },
            {
              id: '3122',
              name: '',
            },
          ],
        },
        {
          id: '313',
          name: '313',
        },
      ],
    },
    3121: {
      id: '312',
      name: '312',
      spouse: {
        name: '312s',
      },
      children: [
        {
          id: '3121',
          name: '',
          spouse: {
            name: '3121s',
          },
          children: [
            {
              id: '31211',
              name: '31211',
            },
          ],
        },
        {
          id: '3122',
          name: '3122',
        },
      ],
    },
  };
  return family_data[node_id];
}

function replace_one_children(selected_node_family, child_node_data) {
  let chlidren_of_selected_node_family = selected_node_family['children'];
  let child_index_to_replace = _.findIndex(chlidren_of_selected_node_family, { id: child_node_data.id });
  if (child_index_to_replace != -1) {
    chlidren_of_selected_node_family.splice(child_index_to_replace, 1, child_node_data);
  }
  // Using unionBy, being replaced but not at the same place, it is in beginning
  //    let res = _.unionBy([merged_json], chlidren_of_selected_node_family, 'id');
}

function get_families(selected_node_sequence) {
  let existing_json = {};
  for (let i = 0; i < selected_node_sequence.length; i++) {
    let selected_node_id = selected_node_sequence[i];
    let selected_node_family = get_family_json(selected_node_id);

    if (_.isEmpty(existing_json)) {
      // Existing is empty => nothing to do.
      existing_json = selected_node_family;
    } else if (existing_json.id == selected_node_id) {
      // selected_node is top node in current data => merge both
      console.log(
        'pre merge selected_node_family of ' + selected_node_id,
        JSON.stringify(selected_node_family, null, 4),
      );

      let selected_node_data_only = lookup_child(selected_node_family, selected_node_id);
      console.log(
        'incoming selected_node_data_only ' + selected_node_id,
        JSON.stringify(selected_node_data_only, null, 4),
      );

      console.log('existing json', JSON.stringify(existing_json, null, 4));
      let merged_json = copy(merge(selected_node_data_only, existing_json));
      console.log('merged json for ' + selected_node_id, JSON.stringify(merged_json, null, 4));

      // selected_node_data_only = merged_json // assigning reference to incoming data
      /*
            get 312's data from selected_node_family and replace it with this. find element in array and replace with merged_json
            */
      replace_one_children(selected_node_family, merged_json);

      console.log(
        'post merge selected_node_family of ' + selected_node_id,
        JSON.stringify(selected_node_family, null, 4),
      );
      existing_json = selected_node_family;
    } else {
      // selected_node is kid in current data => find selected_node's parent in this and merge with that. Then assign back
      let selected_node_parent_id = selected_node_family.id;
      let selected_node_parent_data = lookup_child(existing_json, selected_node_parent_id);
      let merged_selected_node_family = copy(merge(selected_node_parent_data, selected_node_family));
      // This is not going to work..as this variable's (existing_family_of_selected_node) scope will be over
      // existing_family_of_selected_node = merged_selected_node_family
      let grandparent_node_data = lookup_parent(existing_json, selected_node_parent_id);
      if (!grandparent_node_data) {
        // Means parent is at top and no grandparent
        existing_json = merged_selected_node_family;
      } else {
        replace_one_children(grandparent_node_data, merged_selected_node_family);
      }
    }
    console.log('final existing json after merging', JSON.stringify(existing_json, null, 4));
  }
  return existing_json;
}

function try_sequence(seq) {
  return get_families(seq);
}

function run_get_families() {
  let last_full_family = null;
  let sequences = [
    ['3121', '312', '3122'],
    ['3121', '3122', '312'],
    ['3122', '3121', '312'],
    ['3122', '312', '3121'],
    ['312', '3121', '3122'],
    ['312', '3122', '3121'],
  ];
  for (let i = 0; i < sequences.length; i++) {
    let current_family_json = try_sequence(sequences[i]);
    if (last_full_family) {
      if (_.isEqual(last_full_family, current_family_json)) {
        console.log('Did not match: seq' + i, sequences[i]);
      } else {
        console.log('Matched: seq' + i, sequences[i]);
      }
    } else {
      last_full_family = current_family_json;
    }
  }
}

// test_merge_main();
// run_get_families();

export const mergeFamilyDetails = (existing_json, selected_node_family, selected_node_id) => {
  if (_.isEmpty(existing_json)) {
    // Existing is empty => nothing to do.
    existing_json = selected_node_family;
  } else if (existing_json.id == selected_node_id) {
    // selected_node is top node in current data => merge both
    console.log('pre merge selected_node_family of ' + selected_node_id, JSON.stringify(selected_node_family, null, 4));

    let selected_node_data_only = lookup_child(selected_node_family, selected_node_id);
    console.log(
      'incoming selected_node_data_only ' + selected_node_id,
      JSON.stringify(selected_node_data_only, null, 4),
    );

    console.log('existing json', JSON.stringify(existing_json, null, 4));
    let merged_json = copy(merge(selected_node_data_only, existing_json));
    console.log('merged json for ' + selected_node_id, JSON.stringify(merged_json, null, 4));

    // selected_node_data_only = merged_json // assigning reference to incoming data
    /*
        get 312's data from selected_node_family and replace it with this. find element in array and replace with merged_json
        */
    replace_one_children(selected_node_family, merged_json);

    console.log(
      'post merge selected_node_family of ' + selected_node_id,
      JSON.stringify(selected_node_family, null, 4),
    );
    existing_json = selected_node_family;
  } else {
    // selected_node is kid in current data => find selected_node's parent in this and merge with that. Then assign back
    let selected_node_parent_id = selected_node_family.id;
    let selected_node_parent_data = lookup_child(existing_json, selected_node_parent_id);
    let merged_selected_node_family = copy(merge(selected_node_parent_data, selected_node_family));
    // This is not going to work..as this variable's (existing_family_of_selected_node) scope will be over
    // existing_family_of_selected_node = merged_selected_node_family
    let grandparent_node_data = lookup_parent(existing_json, selected_node_parent_id);
    if (!grandparent_node_data) {
      // Means parent is at top and no grandparent
      existing_json = merged_selected_node_family;
    } else {
      replace_one_children(grandparent_node_data, merged_selected_node_family);
    }
  }
  console.log('final existing json after merging', JSON.stringify(existing_json, null, 4));
  return existing_json;
};

// let curr_node_id = 3122;
// let fam_3122 = get_family_json(curr_node_id);
// console.log('fam_3122_node family from server is', JSON.stringify(fam_3122, null, 4));
// /*
//     How to update fam_3122 in existing json
//     xxxx => this does not work => In existing, search for 3122 node and merge it at that level
// */
// let top_of_incoming_family_id = fam_3122.id;
// let top_node_in_existing = lookup_child(existing_json, top_of_incoming_family_id);
// console.log('top_node_in_existing is', JSON.stringify(top_node_in_existing, null, 4));

// let merge_3122_result = merge_anything.merge(top_node_in_existing, fam_3122);

// console.log('merging 3122 with existing is', JSON.stringify(merge_3122_result, null, 4));

// top_node_in_existing = merge_3122_result;

// console.log('existing family after getting 3122 is', JSON.stringify(top_node_in_existing, null, 4));
